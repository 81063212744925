<template>
  <div class="projects">
    <div class="container fluid">
      <project-table
        :getProjectsAPI="projectAPI.getProjects"
        :getDevelopersAPI="developerAPI.getDevelopers"
        projectDetailRouteName="ProjectsDetail"
      ></project-table>
    </div>
  </div>
</template>

<script>
import ProjectTable from "@/modules/Project/components/ProjectTable";
import { project, developer } from "@/api";

export default {
  components: {
    ProjectTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      projectAPI: project,
      developerAPI: developer
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
